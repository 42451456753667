import React from "react"
import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import Hero from "../components/hero/hero"
import {graphql} from 'gatsby'


const IndexPage = ({data}) => {

  const items = data.allNodePromotional.nodes
  console.log(items)
  console.log('here')
  
  return (

    <Layout>
      <SEO title="Home" />
      <Hero />
      <div className="component-wrapper">
          <h1>Welcome</h1>
          <p>We're still getting ready.</p>
      </div>
    </Layout>

  )
}

export const query = graphql`
  query FrontpagePromos {
    allNodePromotional(limit: 3) {
      nodes {
        drupal_internal__nid
        title
        field_teaser_text
      }
    }
  }
`

export default IndexPage
